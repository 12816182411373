// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-annualreport-tsx": () => import("./../../../src/pages/about/annualreport.tsx" /* webpackChunkName: "component---src-pages-about-annualreport-tsx" */),
  "component---src-pages-about-byod-tsx": () => import("./../../../src/pages/about/byod.tsx" /* webpackChunkName: "component---src-pages-about-byod-tsx" */),
  "component---src-pages-about-employment-tsx": () => import("./../../../src/pages/about/employment.tsx" /* webpackChunkName: "component---src-pages-about-employment-tsx" */),
  "component---src-pages-about-fromtheprincipal-tsx": () => import("./../../../src/pages/about/fromtheprincipal.tsx" /* webpackChunkName: "component---src-pages-about-fromtheprincipal-tsx" */),
  "component---src-pages-about-history-tsx": () => import("./../../../src/pages/about/history.tsx" /* webpackChunkName: "component---src-pages-about-history-tsx" */),
  "component---src-pages-about-mission-tsx": () => import("./../../../src/pages/about/mission.tsx" /* webpackChunkName: "component---src-pages-about-mission-tsx" */),
  "component---src-pages-about-policies-tsx": () => import("./../../../src/pages/about/policies.tsx" /* webpackChunkName: "component---src-pages-about-policies-tsx" */),
  "component---src-pages-about-uniformshop-tsx": () => import("./../../../src/pages/about/uniformshop.tsx" /* webpackChunkName: "component---src-pages-about-uniformshop-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-education-hscresults-2022-js": () => import("./../../../src/pages/education/hscresults2022.js" /* webpackChunkName: "component---src-pages-education-hscresults-2022-js" */),
  "component---src-pages-education-pastoralcare-js": () => import("./../../../src/pages/education/pastoralcare.js" /* webpackChunkName: "component---src-pages-education-pastoralcare-js" */),
  "component---src-pages-education-primaryyear-js": () => import("./../../../src/pages/education/primaryyear.js" /* webpackChunkName: "component---src-pages-education-primaryyear-js" */),
  "component---src-pages-education-secondaryyear-js": () => import("./../../../src/pages/education/secondaryyear.js" /* webpackChunkName: "component---src-pages-education-secondaryyear-js" */),
  "component---src-pages-enrolment-tsx": () => import("./../../../src/pages/enrolment.tsx" /* webpackChunkName: "component---src-pages-enrolment-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-tsx": () => import("./../../../src/pages/international.tsx" /* webpackChunkName: "component---src-pages-international-tsx" */),
  "component---src-pages-kissandgo-tsx": () => import("./../../../src/pages/kissandgo.tsx" /* webpackChunkName: "component---src-pages-kissandgo-tsx" */),
  "component---src-pages-newsevents-js": () => import("./../../../src/pages/newsevents.js" /* webpackChunkName: "component---src-pages-newsevents-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-ourcommunity-tsx": () => import("./../../../src/pages/ourcommunity.tsx" /* webpackChunkName: "component---src-pages-ourcommunity-tsx" */),
  "component---src-pages-scheduleoffees-tsx": () => import("./../../../src/pages/scheduleoffees.tsx" /* webpackChunkName: "component---src-pages-scheduleoffees-tsx" */),
  "component---src-pages-scholarships-tsx": () => import("./../../../src/pages/scholarships.tsx" /* webpackChunkName: "component---src-pages-scholarships-tsx" */),
  "component---src-pages-signature-tsx": () => import("./../../../src/pages/signature.tsx" /* webpackChunkName: "component---src-pages-signature-tsx" */),
  "component---src-templates-news-events-template-js": () => import("./../../../src/templates/news-events-template.js" /* webpackChunkName: "component---src-templates-news-events-template-js" */)
}

